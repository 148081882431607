module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Black's Portfolio","short_name":"starter","start_url":"/","background_color":"#F857A6","theme_color":"#F857A6","display":"minimal-ui","icon":"src/assets/images/b.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2c7a32aecde564e6e9bac6fb4de54666"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
